import roadmapsBoard from '../svg/roadmap_board.svg';
import initiativeBoard from '../svg/initiative_board.svg';
import trackBoard from '../svg/track_board.svg';
import linkBoard from '../svg/link_board.svg'

export const howItWorksData = [
  {
    title: "Create a roadmap.",
    contentWidth: "w-1/2",
    subtitle: "Map out your product vision for the year.",
    imagePath: roadmapsBoard,
    alt: "inefficient_milestones",
  },
  {
    title: "Set initiatives for each quarter",
    subtitle:
      "Define key feature projects by creating initiatives. Get clear visibility in product development.",
    imagePath: initiativeBoard,
    alt: "assign_tasks",
    isNaturalDirection: false,
  },
  {
    title: "Track Initiatives.",
    contentWidth: "w-2/3",
    subtitle:
      "Set deadlines to each initiative. Provides clear timeline and keeps your team focused.",
    imagePath: trackBoard,
    alt: "track_progress",
  },
  {
    title: "Link initiatives to an epic",
    contentWidth: "w-2/3",
    subtitle:
      "Epics will house the detailed tasks and milestones that need to be completed to achieve the initiative.",
    imagePath: linkBoard,
    alt: "assign_tasks",
    isNaturalDirection: false,
    showLink: true,
    navText: "Learn more about Epics",
    showBtn: true,
  },
];