import { howItWorksData } from "../data/howItWorksData";
import HowItWorks from "./HowItWorks";

const HowItWorksList = () => (
  <div className="flex flex-col gap-12 mb-12">
    {howItWorksData.map((item, index) => (
      <HowItWorks
        key={index}
        title={item.title}
        // contentWidth={item.contentWidth}
        subtitle={item.subtitle}
        imagePath={item.imagePath}
        alt={item.alt}
        isNaturalDirection={item.isNaturalDirection}
        showLink={item.showLink}
        navText={item.navText}
        showBtn={item.showBtn}
      />
    ))}
  </div>
);
export default HowItWorksList;