import React from "react";
import Section from './Section';
import FeatureBox from './FeatureBox';
import CallToAction from './CallToAction';
import Button from "./Button";
import activityBoard from '../../src/svg/activity_container_board.svg';
import HowItWorksList from "./HowitWorksList";

const commonStyles = 'line-through hidden  md:inline-block'

const Home: React.FC = () => {
  const handelNavigate = ()=>{
    window.location.assign('https://app.topigon.com');
  }
  return (
    <main>
      <div className="flex items-center flex-col">
        <div className="container flex flex-col text-center mx-auto mt-8 md:mt-16 p-4">
          <h1 className="text-3xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-dark  mb-1 md:mb-4">
            <span className={commonStyles}>Missed</span>
            <span className="text-primary"> Meet</span> deadlines.<br />
          </h1>
          <h1 className="text-3xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-dark  mb-1 md:mb-4">
            <span className={commonStyles}>Unwanted</span>
            <span className="text-primary"> Manage</span> scope creep.<br />
          </h1>
          <h1 className="text-3xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-dark  mb-1 md:mb-4">
            <span className={commonStyles}>Inefficient</span>
            <span className="text-primary"> Seamless</span> communication.
          </h1>
          <h2 className="text-base sm:text-lg md:text-xl lg:text-xl xl:text-2xl font-medium text-mediumDark pt-2 sm:pt-4 md:pt-6 lg:pt-8 xl:pt-10 w-2/3 mx-auto mb-4 md:mb-10">
            Streamline tasks, track progress, and achieve your goals with our project management tool.
          </h2>
        </div>
        <Button className="items-center w-[330px] h-12 rounded-lg mx-auto" onClick={handelNavigate} >Get Started</Button>
        <div className="flex-shrink lg:mx-10 mt-12 md:mt-24">
          <img src={activityBoard} alt="Topigon dashboard" className=" md:block" />
        </div>

      </div>
      <Section title="Solving your project management " subtitle="Learn more about Roadmaps" paragraph="Managing product roadmaps and breaking down large projects is complex and time-consuming, especially for small teams or solo founders." bgColor="bg-grayTint" shouldApplyBg={true} showText={true}>
        <div className="grid md:grid-cols-3 gap-8 md:16">
          <FeatureBox image="./task_breakdown.png" title="Task Breakdown and Estimation" subtitle="Engineers can detail tasks and provide time estimates for accurate planning." showIndicator={true}/>
          <FeatureBox image="./dynamic_timelines.png" title="Dynamic Project Timelines" subtitle="Automatically generate project timelines based on task estimates and dependencies." showIndicator={false}/>
          <FeatureBox image="./real_progress.png" title="Real-time Progress Tracking" subtitle="Monitor task completion and project milestones with up-to-date progress indicators." showIndicator={false}/>
        </div>
      </Section>

      <Section title="Here's how the story goes..." subtitle="" shouldApplyBg={false} alignment="text-center" paragraph="" showText={false}>
        <div className="px-[20px] sm:px-0">
         <HowItWorksList />
        </div>

      </Section>
      <hr className="mx-8 md:mx-16 lg:mx-24 my-2 md:my-4 border-t border-gray-300" />
      <CallToAction />
    </main>
  );
}
export default Home;