import React from 'react';
import ContactForm from './ContactForm';

const CallToAction: React.FC = () => (
  <section className="py-8 md:py-12 lg:py-16 text-center text-dark">
    <div className="container mx-auto px-4 md:px-16 lg:px-48 xl:52">
      <h1 className="text-3xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-dark items-center"><span className="text-primary font-bold">Experience</span> Topigon today</h1>      
      <ContactForm />
    </div>
  </section>
);

export default CallToAction;