import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import DropDown from "./DropDown";
import chevronRight from '../svg/chevron.right.svg'
import chevronRightHover from '../svg/chevron_right_hover.svg'

interface MenuItem {
  title: string;
  link: string;
}

interface MobileMenuProps {
  handleSignUp: () => void;
  handleLogin: () => void;
  setIsMenuOpen:  (value:boolean) => void;
}

const menuItems: MenuItem[] = [
  { title: "Use Cases", link: "/use-cases" },
  { title: "Pricing", link: "/pricing" },
  { title: "Blog", link: "https://blog.topigon.com/" },
];

const MobileMenu = ({ handleSignUp, handleLogin, setIsMenuOpen }: MobileMenuProps) => {
  const handleLinkClick = () => {
    document.body.style.overflow = "auto";
    setIsMenuOpen(false);
  };
  return (
    <div className="md:hidden w-[100vw] flex flex-col justify-between h-[calc(100vh-81px)]">
      {/* Menu links */}
      <div className="pt-2 space-y-1 px-6 ">
        {menuItems.map((item, index) => (
          <div key={index}>
          <Link
            to={item.link}
            className="flex items-center justify-between px-4 py-4 group"
            onClick={handleLinkClick}
          >
            <span className="text-gray-800 text-base font-medium group-hover:text-[#007AFF] transition-colors">
              {item.title}
            </span>
            <div>
            <img
                src={chevronRight}
                alt="Navigation"
                className="group-hover:hidden"
              />

              <img
                src={chevronRightHover}
                alt="Navigation"
                className="hidden group-hover:block"
              />
            </div>
          </Link>
          <div className="border-b border-gray-200"></div>
        </div>
        ))}
      </div>

      {/* Footer with Sign Up and Log In */}
      <div className="flex flex-col items-center gap-2 pb-4">
        {/* <hr  /> */}
        <div className="w-full border-b border-[#DCDCDC] my-4"></div>
        <Button className="w-[90%] rounded-md pt-[2px] h-[49px] font-semibold text-base leading-5" onClick={handleSignUp}>
          Sign Up
        </Button>
        <Button
          variant="text"
          className="block w-full mt-4 px-3 py-2 font-[700] text-[14.49px] leading-[17.3px] text-[#1A73E8] underline underline-offset-4 decoration-2 hover:text-gray-900 hover:bg-gray-50"
          onClick={handleLogin}
        >
          Log In
        </Button>
      </div>
    </div>
  );
};


const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
  if (isMenuOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
  return () => {
    document.body.style.overflow = "auto";
  };
}, [isMenuOpen]);

  const handleLogin = () => {
    window.location.assign("https://app.topigon.com/login");
  };
  const handleSignUp = () => {
    window.location.assign("https://app.topigon.com/register");
  };

  const handleMouseEnter = () => {
    setShowDropDown(true);
  };

  const handleMobileMenuClick = () => {
    setIsMenuOpen(m => !m);
  };

  const handleMouseLeave = () => {
    const menuPopup = document.getElementById("dropdownHover")
    menuPopup?.classList.remove('animate-fadeIn')
    menuPopup?.classList.add('animate-fadeOut')
    setTimeout(() => {
      setShowDropDown(false);
    }, 280)
  };
  return (
    <header className="bg-white sticky top-0 z-50">
      <div className="mx-4 md:mx-14 lg:mx-24 px-4 sm:px-4 mt-2 ">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/" className="text-blue-500 font-bold text-xl" onClick={() => isMenuOpen && setIsMenuOpen(false)}>
                <img
                  src={`${process.env.PUBLIC_URL}/logo-icon/topigon-logo-2.png`}
                  alt="Topigon"
                  className="h-16"
                />
              </Link>
            </div>
            <nav className="hidden md:flex space-x-4 ml-8 font-medium text-gray-800 items-center">
              <div
                className="relative ext-gray-600 hover:text-gray-900 pb-7 mt-7 cursor-pointer"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                id="dropdownHoverButton"
              >
                <button
                  className="text-gray-600 hover:text-gray-900 bg-transparent border-none cursor-pointer"
                >
                  Use Cases
                </button>
                {showDropDown && <DropDown onItemClick={() =>{ setShowDropDown(false) }} />}
              </div>
              <Link to="/pricing" className="text-gray-600 hover:text-gray-900">
                Pricing
              </Link>
              <Link
                to="https://blog.topigon.com"
                className="text-gray-600 hover:text-gray-900"
              >
                Blog
              </Link>
            </nav>
          </div>
          <div className="hidden md:flex items-center gap-2">
            <button
              className="text-gray-600 pt-[2px] w-[92.41px] hover:text-gray-900"
              onClick={handleLogin}
            >
              Log In
            </button>
            <Button className="pt-[2px]" onClick={handleSignUp}>
              Sign Up
            </Button>
          </div>
          <div className="md:hidden">
            <button
              onClick={handleMobileMenuClick}
              className="text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
       <MobileMenu setIsMenuOpen={setIsMenuOpen} handleLogin={handleLogin} handleSignUp={handleSignUp} />
      )}
      <div className="border-b border-gray-200 mx-[32px] md:mx-[70px] lg:mx-[110px] mt-2"></div>
    </header>
  );
};

export default Header;
