import React from 'react';

interface FeatureBoxProps {
  image: string;
  title: string;
  subtitle: string;
  showIndicator: boolean
}

const FeatureBox: React.FC<FeatureBoxProps> = ({ image, title, subtitle, showIndicator }) => (
    <div className="shadow-lg p-6 rounded-lg bg-white flex flex-col items-center relative">
      {showIndicator && <span className='absolute top-4 right-4 ml-auto rounded-xl w-16 bg-cloudGray text-xs text-gray-800 font-extrabold leading-6 flex items-center justify-center' >BETA</span>}
        <img src={image} alt="task_breakdown" className="md:block w-24 h-20 object-contain mt-4" />
        <h3 className="text-lg text-center font-semibold pt-6 text-dark">{title}</h3>
        <p className="text-[#4D4D4D] text-center mt-2 mx-2 font-normal">{subtitle}</p>
    </div>
);

export default FeatureBox;