import React from 'react';
import { Link } from 'react-router-dom';

interface SectionProps {
    title: string;
    subtitle: string;
    children: React.ReactNode;
    bgColor?: string;
    shouldApplyBg: boolean;
    paragraph: string;
    showText: boolean
    alignment?: 'text-left' | 'text-center' | 'text-right' | 'text-justify';
}

const Section: React.FC<SectionProps> = ({ title, subtitle, children, bgColor, shouldApplyBg, alignment, paragraph, showText }) => (
    <section className={`${shouldApplyBg ? "py-16" : "py-0"}`}>
        <div className={`${bgColor} ${shouldApplyBg ? "py-20" : "py-0"} ${alignment}`}>
            <div className="container flex flex-grow flex-col mx-auto px-4">
                <h2 className="text-3xl font-bold flex-full text-coalBlack leading-9 mb-3 font-sans ">{title} {showText && <span className='text-primary leading-9 font-bold text-3xl'>challenges.</span>}</h2>
                <p className=' w-[80%] md:w-[80%] lg:w-[55%] leading-6 text-[#5E5E5E] font-normal text-lg/[21px] mb-8'>{paragraph} </p>
                <div className="container">
                    <Link to="/use-cases/roadmaps" className="text-primary mt-4 sm:text-md md:text-lg lg:text-lg/[21px] inline-block font-semibold underline underline-offset-4 font-sans text-">{subtitle}</Link>
                </div>
                <div className="mt-10 md:mt-16">{children}</div> 
            </div>
        </div>
    </section>
);

export default Section;