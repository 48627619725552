import React from "react";
import Button from "../components/Button";

const NotFound: React.FC = () => {
  const goHome = () => {
    window.location.href = "/";
  }

  return (
    <div
      className="flex flex-col items-center justify-center min-h-[100vh - 273"
      style={{ minHeight: "calc(100vh - 273px)" }}
    >
      <img src={`${process.env.PUBLIC_URL}/404_illustration.png`} className="px-4" alt="404 Not Found illustration" width="510" height="312" />

      <div className="text-center px-8">
        <h1 className="font-bold text-dark text-4xl md:text-5xl pt-24">
          Oops! Lost in the internet abyss
        </h1>
        <p className="font-medium text-fadedGray text-lg md:text-xl pt-4">
          This page is as real as unicorns. Let's gallop you back to safety.
        </p>
        
        <Button className="items-center mt-14 mb-14 w-[330px] h-12 rounded-lg mx-auto" onClick={goHome}>Go Home</Button>
      </div>
    </div>
  );
};

export default NotFound;
