import React from "react";
import mapFill from '../svg/map.fill.svg'
import flagFill from '../svg/flag.fill.svg'
import polygon from '../svg/Polygon.svg'
import { useNavigate } from "react-router-dom";

interface DropdownItemProps {
  heading: string;
  subHeading: string;
  description: string;
  image: string;
  to: string;
  onItemClick: () => void;
}

const DropdownItem = ({ heading, subHeading, description, image, to, onItemClick}: DropdownItemProps) => {
  const navigate = useNavigate();
  const handleItemClick = () => {
    navigate(to)
    onItemClick && onItemClick()
  }
  return (
    <div className="flex flex-col p-4 pb-3 gap-4">
        <h1 className="text-grayCustom font-sans  font-bold text-sm">
          {heading}
        </h1>
        <div className="flex gap-4 p-2 hover:rounded-[8px] hover:bg-[#E7F2FF] border-2 border-transparent transition-all transition-colors duration-300 cursor-pointer" onClick={handleItemClick}>
          <div className="w-[28px] h-[28px] mt-2">
            <img src={image} alt="img" className="w-100 h-100" />
          </div>
            <p className="font-sans text-coalBlack font-semibold text-base">
            {subHeading}
            <p className="text-slateGray font-sans font-normal text-sm">
            {description}
            </p>
            </p>
        </div>
    </div>
  )
}
interface DropdownProps {
  onItemClick: () => void;
}
const DropDown: React.FC< DropdownProps> = ({onItemClick}) => {
  return (
    <div className="relative left-24 animate-fadeIn bg-red-600 " id="dropdownHover">
      <div className="absolute top-2 -left-[4.5rem]">
        <img src={polygon} alt="upArrow" />
      </div>
      <ul aria-labelledby="dropdownHoverButton">
        <div className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-[408px] -translate-x-[65%] px-4 cursor-default">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-lg bg-white text-sm/6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-1 w-24rem">
              <DropdownItem
                heading="PRODUCT MANAGERS"
                subHeading="Roadmaps"
                description="Plan and track your product’s journey, quarter by quarter."
                image={mapFill}
                to="/use-cases/roadmaps"
                onItemClick={onItemClick}
              />
              <DropdownItem
                heading="DEVELOPERS"
                subHeading="Epics"
                description="Plan and track your product’s journey, quarter by quarter."
                image={flagFill}
                to="/use-cases/epics"
                onItemClick={onItemClick}
              />
            </div>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default DropDown;
