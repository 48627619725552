import React from 'react';
import { Link } from 'react-router-dom';

interface HowItWorksProps {
    title: string;
    subtitle: string;
    imagePath: string;
    alt: string;
    isNaturalDirection?: boolean;
    showLink?: boolean;
    navText?: string;
    showBtn?: boolean
    textClasses?: string;
    contentWidth?: string | boolean;
    titleWidth?: string | boolean
}

const HowItWorks: React.FC<HowItWorksProps> = ({ title, subtitle, imagePath, isNaturalDirection = true, alt, showLink, navText, showBtn , textClasses, contentWidth = '' , titleWidth = ''}) => (
    <div className={`flex justify-center sm:justify-between w-full gap-12 sm:items-center flex-col-reverse ${isNaturalDirection ? "sm:flex-row" : "sm:flex-row-reverse"}`}>
        <div className={`flex flex-col  gap-4 ${isNaturalDirection ? 'basis-4/12' : 'basis-8/12'} text-left `}>
        <div className='sm:mx-auto w-[100%] sm:w-[70%] md:w-[50%]'>
                    {showBtn && <button className={`bg-cloudGray text-gray-800 text-sm rounded-xl w-16 font-extrabold `}>BETA</button>}
                    <h3 className={`text-lg/[21px] xl:text-xl font-semibold text-coalBlack mt-4 mb-2 `}>{title}</h3>
                    <p className={`text-slateGray lg:text-lg/[1.4rem]  mb-5`}>{subtitle}</p>
                    {
                        showLink && <Link to='/use-cases/epics' className="text-primary mt-4 text-base sm:text-md md:text-lg lg:text-lg w-full lg:w-1/2 font-semibold underline  underline-offset-4 leading-5">{navText}</Link>
                    }
                </div>
        </div>
        <div className={`w-full flex justify-center ${isNaturalDirection ? 'basis-8/12' : 'basis-4/12'}`}>
            <img src={imagePath} alt={alt} className="w-100 h-100" />
        </div>
    </div>
);

export default HowItWorks;